<template>
    <div id="carousel">
        <transition name="slide-fade" mode="out-in">
            <div class="mcontainer">
                <div class="md:flex justify-between relative md:mb-4 mb-3">
                    <div class="flex-1 d-inline">
                        <h2 class="text-lg font-semibold"> Carousel </h2>
                        <br>
                    </div>
                    <a href="" @click.prevent="addItem" class="flex items-center justify-center h-9 lg:px-5 rounded-md bg-blue-600 text-white space-x-1.5 absolute right-0">
                        <div class="flex items-center">
                            <Icon icon="uiw:user-add" class="mr-3" />
                            <span> Add Carousel</span>
                        </div>
                    </a>
                </div>

                <div class="card">
                    <div v-for="item in data" :key="item.id" class="cursor-pointer hover:bg-slate-200 p-2" @click="editItem(item)">
                        <div class="flex">
                            <div class="mr-2 rounded-sm">
                                <img :src="item.background" width="150" class="rounded-sm">
                            </div>
                            <div>
                                <h5 class="font-semibold text-lg truncate">{{ item.title }}</h5>
                                <p>{{ item.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <b-modal v-model="form.show" :title="form.title" ok-title="Submit" :hide-footer="true">
            <form @submit.prevent="">
                <b-form-group>
                    <div class="image-uploader mb-3">
                        <input type="file" ref="images" @change="onFileSelected">
                        <span @click="$refs.images.click()"><Icon icon="bi:cloud-upload" /></span>
                        <img :src="form.previewImage ? form.previewImage : $placeholder2" alt="Images" class="w-80">
                    </div>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        v-model="form.data.title"
                        placeholder="Add a title"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        v-model="form.data.description"
                        placeholder="Add description"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        v-model="form.data.link_button"
                        placeholder="Add a link"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        v-model="form.data.facebook"
                        placeholder="Add facebook url"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        v-model="form.data.linkedin"
                        placeholder="Add linkedin url"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        v-model="form.data.instagram"
                        placeholder="Add instagram url"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        v-model="form.data.twitter"
                        placeholder="Add twitter url"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        v-model="form.data.whatsapp"
                        placeholder="Add whatsapp number"
                    ></b-form-input>
                </b-form-group>
                <b-form-group align="right">
                    <div>
                        <b-button v-if="form.data.id" variant="danger" class="mr-2" @click="deleteItem">
                            Delete
                        </b-button>
                        <b-button variant="primary" type="submit" @click="submit" size="sm">
                            Submit
                        </b-button>
                    </div>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'
    import { mapState, mapActions } from 'vuex'

    export default {
        components: {
            Icon
        },
        computed: {
            ...mapState('carousel', ['loading', 'data'])
        },
        data () {
            return {
                form: {
                    show: false,
                    loading: false,
                    title: '',
                    previewImage: '',
                    data: {
                        id: '',
                        background: '',
                        title: '',
                        description: '',
                        link_button: '',
                        facebook: '',
                        linkedin: '',
                        instagram: '',
                        twitter: '',
                        whatsapp: ''
                    }
                }
            }
        },
        methods: {
            ...mapActions('carousel', ['fetch', 'create', 'update', 'delete']),
            closeModal () {
                this.form.show = false
                this.form.loading = false
                this.form.title = ''
                this.form.previewImage = ''
                this.form.data = {
                    id: '',
                    background: '',
                    title: '',
                    description: '',
                    link_button: '',
                    facebook: '',
                    linkedin: '',
                    instagram: '',
                    twitter: '',
                    whatsapp: ''
                }
            },
            onFileSelected (e) {
                this.form.data.background = e.target.files[0]
                this.form.previewImage = URL.createObjectURL(e.target.files[0])
            },
            submit () {
                if (this.form.data.id) {
                    this.update({ id: this.form.data.id, data: this.form.data })
                        .then(() => {
                            this.$swal(
                                'Updated!',
                                'Carousel item has been updated.',
                                'success'
                            )
                            this.closeModal()
                            this.fetch()
                        })
                } else {
                    this.create(this.form.data)
                        .then(() => {
                            this.$swal(
                                'Added!',
                                'Carousel item has beed added.',
                                'success'
                            )
                            this.fetch()
                            this.closeModal()
                        })
                }
            },
            addItem () {
                this.form.show = true
                this.form.title = 'Add new carousel'
                this.form.data = {
                    id: '',
                    background: '',
                    title: '',
                    description: '',
                    link_button: '',
                    facebook: '',
                    linkedin: '',
                    instagram: '',
                    twitter: '',
                    whatsapp: ''
                }
            },
            editItem (item) {
                this.form.show = true
                this.form.title = 'Edit carousel'
                this.form.previewImage = item.background
                this.form.data = {
                    id: item.id,
                    background: item.background,
                    title: item.title,
                    description: item.description,
                    link_button: item.link_button,
                    facebook: item.facebook,
                    linkedin: item.linkedin,
                    instagram: item.instagram,
                    twitter: item.twitter,
                    whatsapp: item.whatsapp
                }
            },
            deleteItem () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.delete(this.form.data.id).then(() => {
                            this.$swal(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.fetch()
                            this.closeModal()
                        })
                    }
                })
            }
        },
        created () {
            this.fetch()
        }
    }
</script>
